import _implicitStylesheets from "./notfound.css";

import _lightningLayout from "lightning/layout";
import _lightningLayoutItem from "lightning/layoutItem";
import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {t: api_text, h: api_element, c: api_custom_element} = $api;
  return [api_element("article", {
    classMap: {
      "slds-card": true,
      "slds-var-m-around_x-small": true
    },
    key: 0
  }, [api_custom_element("lightning-layout", _lightningLayout, {
    key: 1
  }, [api_custom_element("lightning-layout-item", _lightningLayoutItem, {
    props: {
      "padding": "around-small",
      "size": "12"
    },
    key: 2
  }, [api_element("div", {
    key: 3
  }, [api_text("Ooops, this page doesnt exist :(")])])])])];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "common-notfound_notfound-host",
  shadowAttribute: "common-notfound_notfound"
};
